<template>
  <div class="rich__text-container">
    <div class="rich__text-title">{{ content.title }}</div>
    <div class="rich__text-icon-container">
      <p v-if="content.icon.type === 'emoji'">{{ content.icon.val }}</p>
      <img v-else :src="content.icon.val" alt="" />
    </div>
    <div class="rich__text-html" v-html="content.html"></div>
    <div tag="div" @click="goBack" class="rich__text-back">返回</div>
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable global-require */
const richText = [
  {
    type: 'briefs',
    title: '致「时光」的友人',
    icon: {
      type: 'img',
      val: require('@/assets/images/440.png'),
    },
    html: '<p>致「时光」的友人：</p><p><br/></p><p>哲学家席勒有句名言，说时间的步伐有三种：</p><p>「未来姗姗来迟，现在像箭一样飞逝，过去永远静立不动」这种飞驰在路上的感觉，相信我们所有人都有切身感受</p><p><br/></p><p>过去永远静立不动？</p><p>可当我们回顾过去，却昔日好友 散落天涯～</p><p>有个一禅小和尚的短视频说：</p><p>现代人最大的默契就是不拉黑 不删除</p><p>但也不再联系 最后再也没有遇到过</p><p>最终就沦为互不相关</p><p><br/></p><p>过去永远静立不动！</p><p>之所以面临上面描述的无奈局面</p><p>是因为从来没有一个工具帮助我们</p><p>把我们的过去连接在一起，</p><p>并以此建立深层的超越时间的连接！</p><p><br/></p><p>现在我们要进行一次尝试</p><p>「时光」专门为此打造</p><p>在这里建设你，也建设我</p><p>建设我们共同的时光～🎉🎉</p><p><br/></p><p>2019.01.24</p>',
  },
  {
    type: 'explain',
    title: '使用说明',
    icon: {
      type: 'img',
      val: require('@/assets/images/441.png'),
    },
    html: '<p><b>1、是什么</b></p><p><br/></p><p>「时光」是基于微信的社交应用 简洁实用，</p><p><br/></p><p>它可以帮助亲朋之间增进了解 加强联结，帮我们建立紧密 超越时间的亲友关系，</p><p><br/></p><p>开启人生更多可能，收获生活更多喜悦。</p><p><br/></p><p><br/></p><p><b>2、如何使用</b></p><p><br/></p><p>珍藏时光：发布内容、关联亲友，和亲朋好友一起度过的美好时光，要好好珍藏；</p><p><br/></p><p>话题分享：创建分享主题、发布内容，通过主题内容互相了解，携手探索美好未来；</p><p><br/></p><p>以上就是建立紧密关系的全部工作 非常简洁。</p><p><br/></p><p><br/></p><p><b>3、寄语</b></p><p><br/></p><p>相知无远近，万里尚为邻</p><p><br/></p><p>最幸福的莫过于和亲友一起变得越来越好，抓紧熟悉起来，尽快和亲友一起开启全新生活体验！</p>',
  },
  {
    type: 'private',
    title: '隐私政策',
    icon: {
      type: 'img',
      val: require('@/assets/images/442.png'),
    },
    html: `<p><b>生效日期：2024年1月25日</b></p>
      <p><br /></p>
    <p>欢迎访问 crosscrew.cc网站、微信公众号“CROSSiDEA如是灵感”及相应手机应用程序“时光”等产品以使用我们提供的产品及服务。在使用时光之前，请务必仔细阅读以下隐私政策。</p>
      <p><br/><p>
      <p><b>1、信息的收集与使用</b></p>
      <p>
        我们可能收集您提供的个人信息，包括但不限于您的姓名、电子邮件地址和个人简介。我们将使用这些信息来提供时光的相关服务，包括但不限于社交功能和个性化的推荐。
      </p>
      <p><br/><p>
      <p><b>2、共享个人信息</b></p>
      <p>
        时光不会出售、出租或以其他方式分享您的个人信息给第三方，除非获得您的明确同意或基于法律要求。
      </p>
      <p><br/><p>
      <p><b>3、安全性</b></p>
      <p>
        我们采取了一系列合理的安全措施来保护您的个人信息。然而，互联网的安全性无法完全保障，因此我们不能保证您的个人信息的绝对安全。
      </p>
      <p><br/><p>
      <p><b>4、Cookie和类似技术</b></p>
      <p>
        时光可能使用cookie和类似技术来提高用户体验，例如存储用户的偏好设置。您可以通过浏览器设置拒绝cookie。
      </p>
      <p><br/><p>
      <p><b>5、未成年人隐私</b></p>
      <p>
        时光的服务不适用于未满法定年龄的未成年人。如果您是未成年人，请在法定监护人的监护下使用时光。
      </p>
      <p><br/><p>
      <p><b>6、隐私政策的修改</b></p>
      <p>
        时光保留在任何时候修改或更改本隐私政策的权利。我们将通过适当的方式通知用户。继续使用时光将表示您接受这些变更。
      </p>
      <p><br/><p>
      <p><b>7、联系我们</b></p>
      <p>
        如果您有任何疑问或意见，请 <a style="color:#576b94;" href="http://time.crosscrew.cc/instructions/feedback"
          ><b>联系我们</b</a
        >。
      </p>`,
  },
  {
    type: 'protocol',
    title: '用户协议',
    icon: {
      type: 'img',
      val: require('@/assets/images/442.png'),
    },
    html: `<p><b>生效日期：2024年1月25日</b></p>
      <p><br /></p>
      <p>
        欢迎访问 crosscrew.cc
        网站、微信公众号“CROSSiDEA如是灵感”及相应手机应用程序“时光”等产品以使用我们提供的产品及服务。我们的服务由武进区湖塘生活软件开发工作室（下称“时光”或“我们”）基于本用户协议而提供。请仔细阅读。
      </p>
      <p><br /></p>
      <p><b>1、接受协议</b></p>
      <p>
        通过访问或使用时光，您同意接受本用户协议的所有条款和条件。如果您不同意这些条款和条件，请停止使用时光。
      </p>
      <p><br /></p>
      <p><b>2、账户和注册</b></p>
      <p>
        为了使用时光的某些功能，您可能需要创建一个账户。您应对提供的注册信息的准确性负责，并对账户的安全性负责。您不得使用其他用户的账户。
      </p>
      <p><br /></p>
      <p><b>3、隐私</b></p>
      <p>
        您的隐私对我们非常重要。请查阅我们的隐私政策，以了解我们如何收集、使用和共享您的个人信息。
      </p>
      <p><br /></p>
      <p><b>4、使用规范</b></p>
      <p>
        您同意不会在时光上发布违法、淫秽、威胁、骚扰、诽谤、侵犯他人权利的内容。您还同意遵守适用的法律和法规。
      </p>
      <p><br /></p>
      <p><b>5、知识产权</b></p>
      <p>
        时光的所有内容，包括但不限于文本、图形、标识、图像等，都是时光或其许可方的财产。未经授权，您不得以任何方式复制、修改、传播、出售或使用这些内容。
      </p>
      <p><br /></p>
      <p><b>6、责任限制</b></p>
      <p>
        时光将尽力保持服务的安全性和可用性，但不对服务的中断、延迟或数据丢失负责。您理解并同意，使用时光是基于您个人的风险。
      </p>
      <p><br /></p>
      <p><b>7、解除协议</b></p>
      <p>时光有权根据自己的判断随时终止或暂停您的账户，如果我们认为您违反了本协议的任何规定。</p>
      <p><br /></p>
      <p><b>8、变更和修改</b></p>
      <p>
        时光保留在任何时候修改或更改本用户协议的权利。变更后，我们将通过适当的方式通知用户。继续使用时光将表示您接受这些变更。
      </p>
      <p><br /></p>
      <p><b>9、联系我们</b></p>
      <p>
        如果您有任何疑问或意见，请
        <a style="color: #576b94" href="http://time.crosscrew.cc/instructions/feedback"
          ><b>联系我们</b></a
        >。
      </p>`,
  },
];
export default {
  name: 'views-instructions-rich-text',
  props: ['type'],
  computed: {
    content() {
      return richText.find((item) => item.type === this.type);
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: '/instructions',
      // });
    },
  },
};
</script>

<style></style>
